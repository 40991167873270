import React, { useContext } from "react";
import SummerKindnessSeriesLayout from "@src/layouts/SummerKindnessSeriesLayout";
import SEO from "@src/components/SEO";
import { graphql, useStaticQuery } from "gatsby";
import { ModalContext, ModalType } from "@src/components/modals/ModalController";
import SummerKindnessModalContent from "@src/components/partials/summer-kindness-series/SummerKindnessModalContent";
import window from "global/window";

function SummerKindnessSeriesWeek3() {
  const data = useStaticQuery(graphql`
    {
      headerImage: file(name: { eq: "week3" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 640, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      activity1: file(name: { eq: "chat-circle" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      activity2: file(name: { eq: "thinking-circle" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `);

  const { headerImage, activity1, activity2 } = data;
  const modalContext = useContext(ModalContext);

  return (
    <>
      <SEO
        title="The Summer Kindness Series - Week 3"
        description="A simple, weekly series for families to build new habits at home and inspire compassionate kids!"
        image="https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo's Summer Kindness Series - Week 3",
          description:
            "A simple, weekly series for families to build new habits at home and inspire compassionate kids!",
          image: "https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg",
        }}
      />
      <SummerKindnessSeriesLayout
        header="Explore New Perspectives"
        headerSub="Kindness Habit 3:"
        headerText={
          <>
            Step into someone <br />
            else's shoes
          </>
        }
        headerImage={headerImage}
        whatisIt="What do shoes have to do with kindness? We’re glad you asked! When kids “step in someone else’s shoes” they're building a habit called perspective-taking, which helps them understand the thoughts and feelings of others. It’s a bit like putting on a pair of magical explorer boots that lets them see the world in a whole new way! And maybe discover just how alike we all are too :)"
        whyIsItImportant="When kids get this kindness habit, their world becomes more exciting and dimensional. Did we also mention magical? It takes a big imagination and creativity to think about someone else's point of view. Plus, when disagreements happen, stepping into this habit is where problem solving starts."
        habbits={[
          {
            header: "Get a do-over:",
            text: "Do-overs aren’t just for sports (well the ones we play!). When kids cross the line, ask them to think about how it made others feel, and give them a do-over to start again.",
          },
          {
            header: "Freeze and Think:",
            text: "When there is a conflict, have your kids stop, freeze, and think about how each person must be feeling.",
          },
          {
            header: "Role-play:",
            text: "“How about I pretend to be you, and you be Katie?” Switching roles and re-enacting events is not just helpful for future Oscar nominees - it’s great for kids too!",
          },
          {
            header: "Describe their side:",
            text: "Ever mess up a game of telephone? What'd you say? Us too. It wouldn’t be so hard if you had a clear line :) Help kids develop a super sense by asking them to repeat something back in their own words.",
          },
        ]}
        activitiesText="Practice Exploring New Perspectives at home :) These are ideas to inspire new traditions. Did you know when kids are asked to teach it makes ideas more
              memorable?"
        activities={[
          {
            icon: activity1,
            header: "Mojo's Neighborhood Master Kit",
            text: "(It's Incredibly Delightful and Extremely Necessary)",
            onClick: () => {
              modalContext.showModal(ModalType.SummerKindnessModal, {
                content: (
                  <SummerKindnessModalContent
                    header1="Explore New Perspectives"
                    text1="In this activity, you’ll create a welcome kit for the new kid in the neighborhood, Mojo! Watch the video for instructions!"
                    text2={
                      <>
                        Activity Type: Brainstorm, Craft + Create
                        <br />
                        Play Time: 2 hours to make it incredibly delightful!
                      </>
                    }
                    header2="Explore New Perspectives"
                    items={[
                      { youtubeUrl: "https://www.youtube.com/embed/0PtTOPTKQwU" },
                      {
                        href: "https://static.classdojo.com/img/2018-summer_kindness/mojos-welcome-kit.pdf",
                        text: "Activity PDF",
                        icon: "pdf",
                      },
                    ]}
                  />
                ),
              });
            },
          },
          {
            icon: activity2,
            header: "Kindness Creation Challenge",
            text: "Do you have an idea for a new way to practice Reading Emotions? Adults, submit your family’s ideas for a chance to share it with the world!",
            onClick: () => {
              window.location.href = "https://www.surveymonkey.com/r/kindnesscreation";
            },
          },
        ]}
      />
    </>
  );
}

export default SummerKindnessSeriesWeek3;
